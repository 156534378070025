/* eslint-disable no-console */
export default (Base) => class extends Base {

    nextFrameOnce(callback){
        if(this._localeImportHandle){
            cancelAnimationFrame(this._localeImportHandle);
        }
        return new Promise((resolve) => {
            this._localeImportHandle = requestAnimationFrame(() => {
                resolve(callback());
                this._localeImportHandle = null;
            });
        });
    }

    async setLocale(locale,localizationPath,sourceFile){
        this._localizedContent = null; 
        this.localizationPath = localizationPath;
        this.sourceFile = sourceFile;
        await this.nextFrameOnce(async () => {
            await this.changeLocale(locale);
        });
    }

    async changeLocale(locale){
        this._localizedContent = (await import(`${this.localizationPath}/${locale}/${this.sourceFile}`)).default;
        this.localizationReady = true;
        if(this.requestUpdate){
            this.requestUpdate();
        }
    }

    getLocalizedContent(key){
        if(this._localizedContent == null){
            console.warn(`Trying to get Localization content for "${key}, but it has yet to be loaded"`);
            return "";
        }
        if(this._localizedContent[key] === undefined){
            console.warn(`Missing localization key for "${key}"`, this._localizedContent);
            return "";
        }
        return this._localizedContent[key];
    }

    localizationReady(){
        return this._localizedContent != null;
    }
};